import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Faq"] */ "/vercel/path0/src/components/faq/faq.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupportedPlatforms"] */ "/vercel/path0/src/components/supportedPlatforms/supportedPlatforms.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhyUseWidget"] */ "/vercel/path0/src/components/whyUseWidget/whyUseWidget.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WidgetFeatures"] */ "/vercel/path0/src/components/widgetFeatures/widgetFeatures.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WidgetHero"] */ "/vercel/path0/src/components/widgetHero/widgetHero.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WidgetVideoGuide"] */ "/vercel/path0/src/components/widgetVideoGuide/widgetVideoGuide.comp.tsx");
