'use client';

import React from "react";
import dynamic from "next/dynamic";
import { StyledDiv } from "./widgetVideoGuide.styles";
import { IPlatform, IPlugin } from "../../types";

// Dynamically import ReactPlayer only when this component is rendered
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

export const WidgetVideoGuide = ({ widget, videoUrl, staticMode = false }: { widget: IPlugin, platform?: IPlatform, videoUrl?: string; staticMode?: boolean; }) => {
  if (!widget.videoUrl && !videoUrl) {
    return <></>;
  };

  return (
    <StyledDiv className={`${staticMode ? 'static' : ''}`}>
      <div className="video-container">
        <ReactPlayer
          controls={true}
          width={"100%"}
          height={"523px"}
          style={{ maxWidth: "930px", borderRadius: "10px" }}
          url={videoUrl || widget.videoUrl}
        />
      </div>
    </StyledDiv>
  );
};
