import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	color: ${CSSVars.gray900};
	max-width: 1420px;
	margin: 0 auto;

	padding: 50px 20px;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		padding: 100px 40px;
	}

	&:not(.static) {
		margin: -130px auto 0;

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			margin: -200px auto 0;
		}
	}

	h2 {
		margin: 50px auto 0;
		font-size: ${CSSVars.fontSize2XL};
		font-weight: 600;
		text-align: center;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}

		span {
			color: ${CSSVars.blue500};
		}
	}

	.video-container {
		margin: 0 auto;
		display: flex;
		justify-content: center;

		iframe {
			border-radius: 10px;
			height: auto ;
			max-width: 100%;
			aspect-ratio: 16 / 9;
		}
	}
`;
